export const MODE_DEFAULT = 'DEFAULT';
export const MODE_GRAND_COMPTE = 'GRAND_COMPTE';
export const MODE_PROSPECTION = 'PROSPECTION';

export const availableModes = {
    [MODE_DEFAULT]: {
        'activeLayers': {
            'FR': [
                'French Plots v2',
                'Lignes HTA (extended)',
                'Municipalities France',
                'PS v2',
            ],
            'SE': [
                'Substations (NW Sweden)',
            ],
        },
        'label': 'Default',
    },
    [MODE_GRAND_COMPTE]: {
        'activeLayers': {
            'FR': [
                'French Plots v2',
                'Lignes HTA (extended)',
                'Municipalities France',
                'PS v2',
            ],
        },
        'label': 'Key Accounts',
    },
    [MODE_PROSPECTION]: {
        'activeLayers': {
            'FR': [
                'French Plots v2',
                'JBox and IECharge',
                'Lignes HTA (extended)',
                'Municipalities France',
                'Projects Plot',
                'PS v2',
            ],
        },
        'label': 'Prospection',
    },
};

import React, {useEffect, useRef, useState} from 'react';
import {renderToString} from 'react-dom/server';
import {useDispatch, useSelector} from 'react-redux';
import {reset as resetCountry, setList as setCountryList} from '../redux/countrySlice';
import {setCurrent as setCountry} from '../redux/countrySlice';
import {
    reset as resetLayers,
    setGeneral as setLayersGeneral,
    setIds as setLayersIds,
    setSearch as setLayersSearch,
    setSearchAdd as setLayersSearchAdd
} from '../redux/layersSlice';
import {
    setFilters as setUserFilters,
    setLayers as setUserLayers,
    setMode as setUserMode,
    setSelection as setUserSelection,
    setSubstation as setUserSubstation
} from '../redux/userSlice';
import {useLocation} from 'react-router-dom';
import L from 'leaflet';
import {LayerGroup, LayersControl, Marker, Pane, Polygon, ScaleControl, TileLayer, WMSTileLayer} from 'react-leaflet';
import {useMap} from 'react-leaflet/hooks';
import {
    BoxFill as IconBoxFill,
    BrightnessHigh as IconBrightnessHigh,
    Copy as IconCopy,
    PinAngleFill as IconPinAngleFill
} from 'react-bootstrap-icons';
import {
    COUNTRY_CENTROIDS,
    CountrySelector,
    CustomActions,
    GeneralDataLayers,
    Geoman,
    PolylineMeasure,
    Profile,
    Project,
    Search,
    SearchDataLayers
} from '.';
import {alertService, layerService, operatorService} from '../services';
import {
    availableModes,
    copyToClipboard,
    createMarkerPopup,
    createMetadataPopup,
    getCenter,
    getCoordinatesForGeometry,
    getGeoJsonFromElements,
    getLatLng,
    getMetadataPopup,
    getStyle,
    getUserProfile,
    inverseCoordinates,
    MODE_GRAND_COMPTE,
    MODE_PROSPECTION,
} from '../utils';

export function Map() {
    const dispatch = useDispatch();
    const location = useLocation();
    const map = useMap();

    const country = useSelector(state => state.country.value.current);
    const layersIds = useSelector(state => state.layers.value.ids);
    const layersSearch = useSelector(state => state.layers.value.search);
    const userMode = useSelector(state => state.user.value.mode);
    const userLayers = useSelector(state => state.user.value.layers);
    const userSelection = useSelector(state => state.user.value.selection);
    const userSubstation = useSelector(state => state.user.value.substation);

    const markerIconInstallation = new L.DivIcon({
        'className': 'leaflet-marker-icon-custom leaflet-marker-icon-zone',
        'iconAnchor': [24, 24],
        'iconSize': [48, 48],
        'html': renderToString(<IconBoxFill size={48}/>),
    });
    const markerIconPin = new L.DivIcon({
        'className': 'leaflet-marker-icon-custom leaflet-marker-icon-pin',
        'iconAnchor': [0, 48],
        'iconSize': [48, 48],
        'html': renderToString(<IconPinAngleFill size={48}/>),
        'popupAnchor': [24, -24], // does not behave the same when a default icon and a React Leaflet marker icon
    });
    const markerIconSubstation = new L.DivIcon({
        'className': 'leaflet-marker-icon-custom leaflet-marker-icon-zone',
        'iconAnchor': [24, 24],
        'iconSize': [48, 48],
        'html': renderToString(<IconBrightnessHigh size={48}/>),
    });
    const markerRef = useRef(null);

    const [filtersShow, setFiltersShow] = useState(false);
    const [layerNearbyMunicipalities, setLayerNearbyMunicipalities] = useState(null);
    const [layerSelectedCoordinates, setLayerSelectedCoordinates] = useState(null);
    const [layerSelectedPlots, setLayerSelectedPlots] = useState(null);
    const [layerSubstationZones, setLayerSubstationZones] = useState(null);
    const [markerLayerGroup, setMarkerLayerGroup] = useState(null);
    const [project, setProject] = useState(null);
    const [profileShow, setProfileShow] = useState(false);
    const [projectShow, setProjectShow] = useState(false);
    const [searchIsLoading, setSearchIsLoading] = useState(false);
    const [searchInput, setSearchInput] = useState('');

    // ensure that the latest state is accessed when showing project component's selected plots
    // https://stackoverflow.com/questions/57847594/react-hooks-accessing-up-to-date-state-from-within-a-callback
    const projectRef = useRef(null);
    projectRef.current = project;

    let popup = null;

    // set Leaflet's default icon
    L.Marker.prototype.options.icon = markerIconPin;

    // load available layers
    useEffect(() => {
        loadLayers();

        return () => {
            dispatch(resetCountry());
            dispatch(resetLayers());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    // reload data layers based on selected country
    useEffect(() => {
        loadLayers();
    }, [country]);

    // act based on URL
    useEffect(() => {
        const paths = location.pathname.split('/');

        const searchIndex = paths.indexOf('search');
        if (searchIndex !== -1 && [searchIndex + 1] in paths) {
            const searchValue = paths[searchIndex + 1];
            const searchCoordinates = getLatLng(searchValue);

            if (searchCoordinates !== null) {
                setLayerSelectedCoordinates(searchCoordinates);
                operatorService
                    .near(layersIds['substationZone'], searchCoordinates)
                    .then(items => {
                        if (items.length > 0) {
                            const zones = [];
                            items.forEach((item) => {
                                zones.push(inverseCoordinates(item.geometry.coordinates[0]));
                            });

                            setLayerSubstationZones(zones);
                            map.setView(searchCoordinates, 11);
                        } else {
                            alertService.warn(`No intersecting substation zone found for coordinates ${searchCoordinates[0]},${searchCoordinates[1]}`);
                        }
                    });
            }
        }

        const projectIndex = paths.indexOf('project');
        if (projectIndex !== -1 && [projectIndex + 1] in paths) {
            console.log('Load project...');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location]);

    // listen to right-click to place a marker
    useEffect(() => {
        map.on('contextmenu', e => {
            map.closePopup(); // close any open marker popup
            const latitude = e.latlng.lat.toPrecision(8);
            const longitude = e.latlng.lng.toPrecision(8);
            setLayerSelectedCoordinates([latitude, longitude]);
        });

        // initialize marker layer group
        setMarkerLayerGroup(L.layerGroup().addTo(map));

        return () => {
            map.off('contextmenu');
        };
    }, [map]);

    // clear data when changing modes
    useEffect(() => {
        reset();
    }, [userMode]);

    // react upon user selection
    useEffect(() => {
        if (userSelection !== null) {
            alertService.success(`${userSelection.type} <strong>${userSelection.display_name}</strong> selected`);

            switch (userSelection.type) {
                case 'Coordinates':
                    selectCoordinates();
                    break;
                case 'Installation':
                    selectInstallation()
                    break;
                case 'Municipality':
                    selectMunicipality();
                    break;
                case 'Plot':
                    selectPlot();
                    break;
                case 'Project':
                    reset();
                    selectProject();
                    break;
                case 'Substation':
                    reset();
                    selectSubstation();
                    break;
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userSelection]);

    // take customization settings from backend, fill in missing default values
    const getLayerSettings = layer => {
        let country = layer?.properties?.country || 'FR';
        let scope = layer?.properties?.scope || 'search';
        let style = {
            'active': layer?.customization?.active,
            'default': layer?.customization?.default,
            'dynamic': layer?.customization?.dynamic,
        };
        let type = layer?.properties?.type;

        switch (layer.name) {
            case 'Communes':
            case 'Municipalities France':
            case 'Municipalities Sweden':
                if (style.default === undefined) {
                    style.default = layerService.getDefaultStyles('municipalities');
                }
                type = 'municipality';
                break;

            case 'French Plots v2':
                if (style.default === undefined) {
                    style.default = layerService.getDefaultStyles('plotAll');
                }
                break;

            case 'Parcelles Brute Force':
            case 'Parcelles Brute Force - F3':
            case 'Parcelles Brute Force - F5':
            case 'UF Forced parcels':
                if (style.default === undefined) {
                    style.default = layerService.getDefaultStyles('plot');
                }
                type = 'plot';
                break;

            case 'Electric Lines Sweden':
            case 'Lignes HTA':
            case 'Lignes HTA (extended)':
                if (style.default === undefined) {
                    style.default = {
                        'fillOpacity': 1,
                        'weight': 3,
                    };
                }
                break;

            case 'Projet plots':
            case 'Projet plots (prod)':
                if (style.default === undefined) {
                    style.default = layerService.getDefaultStyles('projectPlots');
                }
                type = 'projectPlot';
                break;

            case 'Zonage PLU':
                if (style.default === undefined) {
                    style.default = {
                        'fillOpacity': 0.05,
                        'opacity': 0.85,
                        'weight': 2,
                    };
                }
                type = 'urbanism';
                break;

            case 'Roads':
            case 'Routes':
            case 'Test Roads':
                if (style.default === undefined) {
                    style.default = {
                        'fillColor': 'purple',
                        'color': 'pink',
                        'fillOpacity': 0.25,
                        'opacity': 1,
                        'weight': 2,
                    };
                }
                type = 'road';
                break;

            case 'PS v2':
                scope = 'general';
                type = 'substation';
                break;

            default: // only if style or type hasn't been set through backend
                if (style.default === undefined) {
                    style.default = {
                        'color': '#E3256B', // border color
                        'fillColor': '#FD7F4F', // shape color
                        'fillOpacity': 1, // shape opacity
                        'opacity': 1, // border opacity
                        'weight': 10, // border width
                    };
                }
                if (type === undefined) {
                    type = 'default';
                }
                break;
        }

        return {
            'country': country,
            'fieldMapping': layer.properties.field_mapping, // undefined if none
            'scope': scope,
            'style': style,
            'type': type,
        };
    }

    // get the municipality closest to center and update layers as municipality search
    const getMunicipalityAndSearch = center => {
        return operatorService.near(layersIds['municipality'], center)
            .then(municipalities => {
                if (municipalities.length === 0) {
                    alertService.warn(`No municipality found near <strong>${center[0]},${center[1]}</strong>`);
                    return;
                }

                const municipality = municipalities.pop();
                searchLayersUpdate(municipality._id, true);
            });
    };

    const loadLayers = () => {
        layerService.getAll()
            .then(layers => {
                if (layers === null) {
                    alertService.error('Failed to connect to backend.');
                    return;
                }

                const localLayersIds = {};
                const localLayersGeneral = [];
                const localLayersSearch = [];
                const countryList = [];
                layers.forEach(layer => {
                    const layerSettings = getLayerSettings(layer);
                    const localLayer = {
                        ...layerSettings,
                        '_id': layer._id,
                        'data': [], // null would stop the layer from showing in layer manager
                        'name': layer.name,
                    };

                    if (country === localLayer.country) {
                        localLayersIds[localLayer.type] = localLayer._id;
                    }

                    if (countryList.includes(layerSettings.country) === false) {
                        countryList.push(layerSettings.country);
                    }

                    if (layerSettings.country === country) {
                        if (layerSettings.scope === 'general') {
                            localLayersGeneral.push(localLayer);
                        } else {
                            localLayersSearch.push(localLayer);
                        }
                    }
                });

                dispatch(setCountryList(countryList.sort()));
                dispatch(setLayersGeneral(localLayersGeneral));
                dispatch(setLayersIds(localLayersIds));
                dispatch(setLayersSearch(localLayersSearch));

                // init user's layers
                let localUserProfile = getUserProfile();
                const localCountry = localUserProfile.country || country;
                const localUserMode = localUserProfile.mode || userMode;
                // init user's profile with layers based on current mode's active layers, only store active
                if (false === localCountry in localUserProfile.layers || 0 === localUserProfile.layers[localCountry].length) {
                    localUserProfile.layers[localCountry] = [];
                    layers.forEach(layer => {
                        if (true === localCountry in availableModes[localUserMode].activeLayers && true === availableModes[localUserMode].activeLayers[localCountry].includes(layer.name)) {
                            localUserProfile.layers[localCountry][layer._id] = layer.name;
                        }
                    });
                }
                dispatch(setCountry(localCountry));
                dispatch(setUserMode(localUserMode));
                dispatch(setUserLayers(localUserProfile.layers));

                // init map center based on active country
                const countryCentroid = COUNTRY_CENTROIDS[localCountry];
                map.setView([countryCentroid.lat, countryCentroid.lng], countryCentroid.zoom);
            });
    };

    const onFeatureSelect = (layer, feature, event, layerRef = null) => {
        const container = L.DomUtil.create('div');

        if (layer.type === 'plot') {
            const plotId = feature.properties.display_name;

            let buttonProjectLabel;
            let buttonProjectOnclick;
            if (project?.plot_ids?.includes(plotId) !== true) {
                buttonProjectLabel = 'Add to project';
                buttonProjectOnclick = () => {
                    projectAddPlots([plotId]);
                    popup.close();
                };
            } else {
                buttonProjectLabel = 'Remove from project';
                buttonProjectOnclick = () => {
                    projectRemovePlot(plotId);
                    popup.close();
                };
            }

            const buttonProject = L.DomUtil.create('button', 'btn btn-primary btn-sm mb-3', container);
            buttonProject.type = 'button';
            buttonProject.innerHTML = buttonProjectLabel;
            buttonProject.onclick = buttonProjectOnclick;

            const buttonPlotId = L.DomUtil.create('button', 'btn btn-outline-secondary btn-sm mb-3 ms-2', container);
            buttonPlotId.type = 'button';
            buttonPlotId.innerHTML = renderToString(<>
                {plotId}
                <IconCopy className="ms-1"/>
            </>);
            buttonPlotId.onclick = () => {
                copyToClipboard(plotId, () => {
                    popup.close();
                });
            };
        } else if (layer.type === 'municipality') {
            // add button to select the municipality
            const buttonSearch = L.DomUtil.create('button', 'btn btn-primary btn-sm mb-3', container);
            buttonSearch.type = 'button';
            buttonSearch.innerHTML = userMode === MODE_PROSPECTION ? 'Load plots' : 'Search';
            buttonSearch.onclick = () => {
                layerRef.resetStyle();
                dispatch(setUserSelection({
                    '_id': feature.id,
                    'geometry': feature.geometry,
                    'data': {
                        'coordinates': getCoordinatesForGeometry(feature.geometry),
                    },
                    'display_name': feature.properties.display_name,
                    'type': 'Municipality',
                }));
                setSearchInput(feature.properties.display_name);
                popup.close();
            };
        }

        // reset styles of all features
        if (layerRef !== null) { // we don't always have a reference, e.g. for react native's municipality polygons
            layerRef.resetStyle();
        }

        // set active style
        const style = getStyle(layer, feature);
        event.target.setStyle({
            'color': layer.style.active?.color || style.color,
            'fillColor': layer.style.active?.color || style.fillColor,
            'fillOpacity': layer.style.active?.fillOpacity || style.fillOpacity,
            'weight': layer.style.active?.weight || style.weight,
        });

        const metadata = L.DomUtil.create('pre', null, container);
        metadata.innerHTML = getMetadataPopup('Metadata', feature.properties);
        popup = L.popup({
            'maxHeight': 250,
            'maxWidth': 300,
        })
            .setContent(container)
            .setLatLng(event.latlng)
            .on('remove', () => {
                // reset style on popup close
                layerRef.resetStyle();
            })
            .openOn(map);
    };

    const onFeaturesSelect = event => {
        const bounds = event.boxSelectBounds;
        const featureIds = [];
        map.eachLayer((layer) => {
            if (
                layer instanceof L.Polygon &&
                'defaultOptions' in layer
                // @TODO: only apply to selected layer types
                // && layer.defaultOptions.type === 'urbanism'
            ) {
                if (bounds.contains(layer.getBounds())) {
                    featureIds.push(layer.feature.properties.display_name);
                    layer.setStyle(layerService.getDefaultStyles('plotSelected'));
                }
            }
        });

        projectAddPlots(featureIds);
    }

    // @TODO: reset feature style on map upon plot removal
    const projectRemovePlot = id => {
        const updatedSelectedPlotIds = project.plot_ids;
        const indexPlotId = updatedSelectedPlotIds.indexOf(id);
        if (indexPlotId !== -1) {
            updatedSelectedPlotIds.splice(indexPlotId, 1);
            setProject({
                ...project,
                'plot_ids': [...updatedSelectedPlotIds],
            });
        }
    }

    const projectAddPlots = newIds => {
        // update project with added plots
        if (projectRef.current !== null) {
            const duplicateIds = [];
            const localPlotIds = projectRef.current.plot_ids !== undefined ? projectRef.current.plot_ids : [];
            newIds.forEach(newId => {
                if (localPlotIds.includes(newId) === true) {
                    duplicateIds.push(newId);
                } else {
                    localPlotIds.push(newId);
                }
            });

            if (duplicateIds.length > 0) {
                alertService.warn(`The plots <strong>${duplicateIds.join(', ')}</strong> have already been selected`);
            }

            setProject({
                ...projectRef.current,
                'plot_ids': localPlotIds,
            });
            // init project with added plots only
        } else {
            setProject({
                'plot_ids': newIds,
                'substation_code': null,
            });
        }

        setProjectShow(true);
    }

    const selectCoordinates = () => {
        const center = [userSelection.data.latitude, userSelection.data.longitude];
        setLayerSelectedCoordinates(center);

        if ([MODE_GRAND_COMPTE, MODE_PROSPECTION].includes(userMode) === true) {
            getMunicipalityAndSearch(center)
                .then(() => {
                    map.setView(center, 15);
                })
                .finally(() => {
                    setSearchIsLoading(false);
                });
        } else {
            map.setView(center, 15);
            setSearchIsLoading(false);
        }
    }

    const selectInstallation = () => {
        const center = [userSelection.data.geometry.coordinates[1], userSelection.data.geometry.coordinates[0]];
        setSearchIsLoading(false);
        map.setView(center, 15);

        L.marker(center, {
            'icon': markerIconInstallation,
        })
            .addTo(markerLayerGroup)
            .bindPopup(createMetadataPopup(userSelection.display_name, userSelection.metadata));
    }

    // note that there's no reset for municipality searches
    const selectMunicipality = () => {
        const coordinates = inverseCoordinates(userSelection.data.coordinates);
        map.fitBounds(coordinates);

        reloadSearchDataLayers(getCenter(coordinates), `municipality ${userSelection.display_name}`, userSelection._id)
            .finally(() => {
                setSearchIsLoading(false);
            });
    }

    // user selects a plot from search suggestions
    const selectPlot = () => {
        const coordinates = inverseCoordinates(userSelection.data.geometry);
        const localLayerSelectedPlots = layerSelectedPlots || [];
        localLayerSelectedPlots.push({
            '_id': userSelection._id,
            'coordinates': coordinates,
            'metadata': userSelection.metadata,
        });
        setLayerSelectedPlots(localLayerSelectedPlots);

        if ([MODE_GRAND_COMPTE, MODE_PROSPECTION].includes(userMode) === true) {
            getMunicipalityAndSearch(getCenter(coordinates))
                .then(() => {
                    map.fitBounds(coordinates);
                })
                .finally(() => {
                    setSearchIsLoading(false);
                });
        } else {
            map.fitBounds(coordinates);
            setSearchIsLoading(false);
        }
    }

    // user selects a project from search suggestions
    const selectProject = () => {
        const center = getCenter(inverseCoordinates([userSelection.data.geometry.coordinates]));

        if (('name.substation' in userSelection.metadata) === false) {
            alertService.warn(`No substation name found in metadata of project <strong>${userSelection.display_name}</strong>`);
            setSearchIsLoading(false);
            return;
        }

        if (('Plot.Id_JSP' in userSelection.metadata) === false) {
            alertService.warn(`No plots found in metadata of project <strong>${userSelection.display_name}</strong>`);
            setSearchIsLoading(false);
            return;
        }

        const substationName = userSelection.metadata['name.substation'];
        return operatorService.getElement(layersIds['substation'], {
            'phrase': [{
                'key': 'display_name',
                'value': substationName,
            }],
        })
            .then(substation => { // this always returns a substation, ensure it's the right
                if (substation === null || substation.display_name !== substationName) {
                    alertService.warn(`No substation found for name <strong>${substationName}</strong>`);
                    setSearchIsLoading(false);
                    return;
                }

                dispatch(setUserSubstation(substation)); // we need to have a substation for reloadSearchDataLayers
                showSubstationMarker(center, substation);

                reloadSearchDataLayers(center, `project ${userSelection.display_name}`, null, layersIds['projectPlot']); // hide all search data layers but this one

                // substation zone has no plots, stop here
                let projectPlotIds = userSelection.metadata['Plot.Id_JSP'];
                if (projectPlotIds === null) {
                    alertService.warn(`No plots found for project <strong>${userSelection.display_name}</strong>`);
                    setSearchIsLoading(false);
                    return;
                }

                // prepare parallelization of plot detail queries
                const promises = [];
                projectPlotIds = projectPlotIds.split(',').map(plotId => plotId.trim());
                projectPlotIds.forEach(plotId => {
                    promises.push(
                        operatorService.getElement(layersIds['plot'], {
                            'phrase': [{
                                'key': 'display_name',
                                'value': plotId,
                            }],
                        })
                    );
                });

                Promise.all(promises).then(plots => {
                    const localLayerSelectedPlots = [];
                    let coordinates = [];
                    let countSuccessfullyRetrievedPlots = 0;
                    plots.forEach(plot => {
                        if (plot !== null) {
                            countSuccessfullyRetrievedPlots++;

                            const inversedCoordinates = inverseCoordinates(plot.geometry.coordinates[0]);
                            localLayerSelectedPlots.push({
                                'id': plot.display_name,
                                'coordinates': inversedCoordinates,
                                'metadata': plot.metadata,
                            });
                            coordinates = [
                                ...coordinates,
                                ...inversedCoordinates,
                            ];
                        }
                    });

                    if (countSuccessfullyRetrievedPlots > 0) {
                        setLayerSelectedPlots(localLayerSelectedPlots);
                        map.setView(getCenter(coordinates), 15);
                    }

                    if (projectPlotIds.length !== countSuccessfullyRetrievedPlots) {
                        alertService.warn(`<strong>${countSuccessfullyRetrievedPlots}</strong> of the project's <strong>${projectPlotIds.length}</strong> plots have been matched.`);
                    }

                    setProject({
                        'name': userSelection.display_name,
                        'plot_ids': localLayerSelectedPlots.map(plot => plot.id),
                        'substation_code': substation.metadata.code,
                    });

                    setSearchIsLoading(false);
                });

                setProjectShow(true);
            });
    }

    const selectSubstation = () => {
        const center = inverseCoordinates([userSelection.geometry.coordinates]).pop();

        // update a fresh project that has not yet been created / persisted on Airtable
        if (project !== null && project.id === undefined) {
            setProject({
                ...project,
                'substation_code': userSelection.metadata.code,
            });
        }

        showSubstationMarker(center, userSelection);

        let showOnlyLayerId = null;
        if ([MODE_PROSPECTION].includes(userMode) === false) {
            showOnlyLayerId = '.'; // hide all search data layers , no ID needed since this is a general data layer (substations)
        }
        reloadSearchDataLayers(center, `substation ${userSelection.display_name}`, null, showOnlyLayerId)
            .finally(() => {
                setSearchIsLoading(false);
            });
    }

    const showSubstationMarker = (center, substationData) => {
        const title = 'Substation' + (substationData.display_name !== undefined ? ` ${substationData.display_name}` : '');
        L.marker(center, {
            'icon': markerIconSubstation,
        })
            .addTo(markerLayerGroup)
            .bindPopup(createMetadataPopup(title, substationData.metadata));
    };

    // update map based on search for a given point
    const reloadSearchDataLayers = (center, warnMessageName, municipalityLayerId = null, showOnlyLayerId = null) => {
        const isMunicipalitySearch = null !== municipalityLayerId;
        // @HACK: we want to show adjacent municipalities as well when "Municipalities France" layer is active, even though they won't intersect with center
        if (true === isMunicipalitySearch && Object.values(userLayers[country]).includes(layersIds['municipality']) === true) {
            operatorService.near(layersIds['municipality'], center, 10000)
                .then(municipalities => {
                    // ignore the current municipality and put municipalities on map
                    setLayerNearbyMunicipalities(municipalities.filter(municipality => municipality._id !== municipalityLayerId));
                });
        }

        // get substation zone for substation, if there's one
        if (null !== userSubstation) {
            return operatorService.getElement(layersIds['substationZone'], {
                'phrase': [{
                    'key': 'metadata.substations.code',
                    'value': userSubstation.code,
                }],
            })
                .then(item => {
                    if (null !== item) {
                        const zones = [inverseCoordinates(getCoordinatesForGeometry(item.geometry))];
                        if (userMode !== MODE_PROSPECTION) {
                            setLayerSubstationZones(zones);
                        }
                        map.fitBounds(zones);

                        searchLayersUpdate(true === isMunicipalitySearch ? municipalityLayerId : item._id, isMunicipalitySearch, showOnlyLayerId);
                    } else {
                        alertService.warn(`No substation zone found for ${userSubstation.code}`);
                    }
                });
        // otherwise, get substation zones nearby then update search layers based on its first id
        } else {
            return operatorService
                .near(layersIds['substationZone'], center, 6000) // if smaller, substation QUIBERON won't intersect
                .then(items => {
                    if (items.length > 0) {
                        const zoneIds = [];
                        const zones = [];
                        items.forEach(item => {
                            zoneIds.push(item._id);
                            zones.push(inverseCoordinates(getCoordinatesForGeometry(item.geometry)));
                        });

                        // don't show substation zones when in prospection mode with a substation search
                        if ('Substation' !== userSelection.type || userMode !== MODE_PROSPECTION) {
                            setLayerSubstationZones(zones);
                        }

                        // don't fit bounds for municipalities
                        if (['Coordinates', 'Municipality', 'Plot'].includes(userSelection.type) === false) {
                            map.fitBounds(zones);
                        }

                        // @TODO: if no municipalityLayerId we only use the very first zone for now, use all
                        searchLayersUpdate(true === isMunicipalitySearch ? municipalityLayerId : zoneIds[0], isMunicipalitySearch, showOnlyLayerId);
                    } else {
                        alertService.warn(`No intersecting substation zone found for ${warnMessageName}`);
                    }
                });
        }
    }

    // update data of one given search data layer
    const searchLayersUpdate = (layerId, isMunicipalitySearch = false, showOnlyLayerId = null) => {
        const searchLayerCount = layersSearch.length;

        // reset data of inactive layers by storing it in oldLayers, to be pushed to layersSearch later
        const oldLayers = [];
        layersSearch
            .filter(layer => !(country in userLayers && Object.keys(userLayers[country]).includes(layer._id) === true))
            .forEach(layer => {
                oldLayers.push({
                    ...layer,
                    'data': [], // null would stop the layer from showing in layer manager
                });
            });

        // intersect current selection with user's active layers, then update layersSearch including oldLayers
        const updatedLayers = [];
        const localUserFilters = {};
        layersSearch
            .filter(layer => country in userLayers && Object.keys(userLayers[country]).includes(layer._id) === true)
            .forEach(layer => {
                let areFeaturesHidden = showOnlyLayerId !== null && layer._id !== showOnlyLayerId;
                let fields = null;

                // only show certain layers in prospection mode
                if (MODE_PROSPECTION === userMode &&
                    'general' !== layer.scope &&
                    [layersIds['plot'], layersIds['installation'], layersIds['highVoltageLine'], layersIds['municipality'], layersIds['projectPlot']].includes(layer._id) === false) {
                    areFeaturesHidden = true;
                }

                if ('plot' === layer.type && false === isMunicipalitySearch) {
                    if (null !== userSubstation) {
                        fields = {
                            'equals': [{
                                'key': 'metadata.substations.FLAG_50m_lines10k',
                                'value': true,
                            }],
                            'phrase': [{
                                'key': 'metadata.substations.code',
                                'value': userSubstation.code,
                            }],
                            'range': [{
                                'key': 'metadata.substations.NUM_rating',
                                'value': 100,
                            }],
                        };

                        operatorService.getElements(layer._id, fields)
                            .then(elements => getGeoJsonFromElements(elements))
                            .then(data => {
                                alertService.info(`${data.length} intersecting elements from layer <strong>${layer.name}</strong> loaded`);
                                updatedLayers.push({
                                    ...layer,
                                    'areFeaturesHidden': areFeaturesHidden,
                                    'data': data,
                                    'defaultPlotFilters': fields,
                                });
                                // setFiltersShow(true); // open filters to show presets

                                if (searchLayerCount === oldLayers.length + updatedLayers.length) {
                                    setSearchIsLoading(false);
                                }

                                const updatedLayersSearch = [...oldLayers, ...updatedLayers];
                                dispatch(setLayersSearch(updatedLayersSearch));
                            });
                    } else {
                        alertService.warn(`No substation to get elements from layer <strong>${layer.name}</strong>`);
                    }
                // non-plot layers or plot layer at municipality level
                } else {
                    if ('plot' === layer.type) {
                        fields = {
                            'equals': [],
                            'phrase': [],
                            'range': [],
                        };
                    }

                    operatorService.intersect(layer._id, layerId)
                        .then(elements => getGeoJsonFromElements(elements))
                        .then(data => {
                            alertService.info(`${data.length} intersecting elements from layer <strong>${layer.name}</strong> loaded`);

                            // merge data for non-plot municipality searches to avoid dropping existing
                            const geoJsonData = 'plot' !== layer.type && true === isMunicipalitySearch ? [...layer.data, ...data] : data;

                            updatedLayers.push({
                                ...layer,
                                'areFeaturesHidden': areFeaturesHidden,
                                'data': geoJsonData,
                                'defaultPlotFilters': fields,
                            });

                            if (searchLayerCount === oldLayers.length + updatedLayers.length) {
                                setSearchIsLoading(false);
                            }

                            const updatedLayersSearch = [...oldLayers, ...updatedLayers];
                            dispatch(setLayersSearch(updatedLayersSearch));
                        });
                }

                // init user filters, this overrides potentially existing filters for that layer
                localUserFilters[layer._id] = fields;
            });

        dispatch(setUserFilters(localUserFilters));
    }

    // clear map
    const reset = () => {
        // clear non-search data layers
        setLayerNearbyMunicipalities(null);
        setLayerSelectedCoordinates(null);
        setLayerSelectedPlots(null);
        setLayerSubstationZones(null);

        // clear search layers
        if (layersSearch !== null) {
            // strip layer objects back to initial state, i.e. empty data object etc.
            const updatedLayersSearch = layersSearch.map(layer => {
                return {
                    ...layer,
                    'data': [], // null would stop the layer from showing in layer manager
                };
            });
            dispatch(setLayersSearch(updatedLayersSearch));
        }

        // clear markers
        if (markerLayerGroup !== null) {
            markerLayerGroup.clearLayers();
        }

        // close popups
        map.closePopup();
    }

    return (
        <>
            <LayersControl position="topright">
                <Pane name="map-osm-greyscale">
                    <LayersControl.BaseLayer checked={false} name="OpenStreetMap Greyscale">
                        <TileLayer
                            attribution="© OpenStreetMap contributors, Tiles style by Humanitarian OpenStreetMap Team hosted by OpenStreetMap France"
                            maxZoom={22}
                            url="https://{s}.tile.openstreetmap.fr/hot/{z}/{x}/{y}.png"
                        />
                    </LayersControl.BaseLayer>
                </Pane>
                <Pane name="map-osm">
                    <LayersControl.BaseLayer checked={false} name="OpenStreetMap">
                        <TileLayer
                            attribution="© OpenStreetMap contributors"
                            maxZoom={22}
                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"/>
                    </LayersControl.BaseLayer>
                </Pane>
                <Pane name="map-otm">
                    <LayersControl.BaseLayer checked={false} name="OpenTopoMap">
                        <TileLayer
                            attribution="Map data: © OpenStreetMap contributors, SRTM | Map style: © OpenTopoMap (CC-BY-SA)"
                            maxZoom={22}
                            url="https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png"/>
                    </LayersControl.BaseLayer>
                </Pane>
                <Pane name="map-google-satellite">
                    <LayersControl.BaseLayer checked={false} name="GoogleMaps Satellite">
                        <TileLayer
                            attribution="© Google Maps"
                            maxZoom={22}
                            subdomains={['mt1', 'mt2', 'mt3']}
                            url="https://{s}.google.com/vt/lyrs=y&x={x}&y={y}&z={z}"
                        />
                        {/* lyrs options, can be accumulated like s,h:
                            h = roads only
                            m = standard roadmap
                            p = terrain
                            r = somehow altered roadmap
                            s = satellite only
                            t = terrain only
                            y = hybrid */}
                    </LayersControl.BaseLayer>
                </Pane>
                <Pane name="map-google-satellite-labels">
                    <LayersControl.BaseLayer checked={true} name="GoogleMaps Satellite Only">
                        <TileLayer
                            attribution="© Google Maps"
                            maxZoom={22}
                            subdomains={['mt1', 'mt2', 'mt3']}
                            url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
                        />
                    </LayersControl.BaseLayer>
                </Pane>
                {country === 'FR' && <>
                    <Pane name="map-geoportail">
                        <LayersControl.Overlay checked={true} name="Géoportail">
                            <WMSTileLayer
                                attribution={'Geoportail'}
                                maxZoom={22}
                                minZoom={0}
                                opacity={.5}
                                tileSize={256}
                                url={'https://data.geopf.fr/wmts?REQUEST=GetTile&SERVICE=WMTS&VERSION=1.0.0&STYLE=normal&TILEMATRIXSET=PM&FORMAT=image/png&LAYER=CADASTRALPARCELS.PARCELLAIRE_EXPRESS&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}'}
                            />
                        </LayersControl.Overlay>
                    </Pane>
                    <Pane name="map-georisques">
                        <LayersControl.Overlay checked={false} name="Géorisques">
                            <WMSTileLayer
                                attribution={'Géorisques'}
                                maxZoom={22}
                                minZoom={12}
                                opacity={.5}
                                params={{
                                    'format': 'image/png',
                                    'layers': ['GEORISQUES_SERVICES'], // ["CAVITE_LOCALISEE"]
                                    'transparent': true,
                                }}
                                tileSize={512}
                                url={'https://www.georisques.gouv.fr/services?'}
                            />
                        </LayersControl.Overlay>
                    </Pane>
                </>}
                {country === 'SE' && <>
                    <Pane name="map-geoportail">
                        <LayersControl.Overlay checked={true} name="Länsstyrelsen">
                            <WMSTileLayer
                                attribution={'Länsstyrelsen'}
                                maxZoom={22}
                                minZoom={0}
                                opacity={1}
                                params={{
                                    'contextualWMSLegend': 0,
                                    'dpiMode': 7,
                                    'featureCount': 10,
                                    'format': 'image/png',
                                    'height':735,
                                    'layers': 'granser,text',
                                    'srs': 'EPSG:3857',
                                    'styles': '',
                                    'transparent': true,
                                    'width': 974,
                                }}
                                url={'https://ext-geoportal.lansstyrelsen.se/wmsproxy/wms/fastighet?'}
                            />
                        </LayersControl.Overlay>
                    </Pane>
                </>}
                <Pane name="selection-zone">
                    {/* ensure that this layer is above all the others */}
                    {layerSubstationZones && (
                        <LayersControl.Overlay checked={true} name="Substation zones">
                            <LayerGroup>
                                {layerSubstationZones.map((layerSubstationZone, key) => (
                                    <Polygon
                                        key={key}
                                        pathOptions={layerService.getDefaultStyles('substationZone')}
                                        positions={layerSubstationZone}
                                    />
                                ))}
                            </LayerGroup>
                        </LayersControl.Overlay>
                    )}
                    {layerNearbyMunicipalities && <LayersControl.Overlay checked name="Nearby Municipalities">
                        <LayerGroup>
                            {layerNearbyMunicipalities
                                // @TODO: understand why a clicked-upon nearbyMunicipality gets here despite being filtered it out on setLayerNearbyMunicipalities, and on top of that, why its coordinates are empty
                                .filter(nearbyMunicipality => nearbyMunicipality.geometry.coordinates !== undefined && nearbyMunicipality.geometry.coordinates.length > 0)
                                .map((nearbyMunicipality, key) => <Polygon
                                    eventHandlers={{
                                        // this aims at duplicating the click-behaviour of layers of type city to make it feel identical to intersected municipality features
                                        'click': event => {
                                            onFeatureSelect({
                                                ...nearbyMunicipality,
                                                'type': 'municipality', // add type to have the popup behave the right way
                                            }, {
                                                '_id': nearbyMunicipality._id,
                                                'geometry': nearbyMunicipality.geometry,
                                                'properties': {
                                                    'display_name': nearbyMunicipality.display_name,
                                                    ...nearbyMunicipality.metadata,
                                                },
                                                'type': 'Feature',
                                            }, event, null) // impossible to get a reference to the layer's geoJson that would allow for resetStyle()
                                        },
                                    }}
                                    key={key}
                                    pathOptions={layerService.getDefaultStyles('municipalities')}
                                    positions={inverseCoordinates(nearbyMunicipality.geometry.coordinates[0])}
                                />)
                            }
                        </LayerGroup>
                    </LayersControl.Overlay>}
                    {layerSelectedPlots && <LayersControl.Overlay checked name="Selected plots">
                        <LayerGroup>
                            {layerSelectedPlots.map((layerSelectedPlot, key) => {
                                const pathOptions = layerService.getDefaultStyles('plotSelected');
                                switch (layerSelectedPlot.metadata['Validation.Status']) { // override default values based on status
                                    case 'OK JB':
                                        pathOptions.fillColor = '#84FF00';
                                        break;
                                    case 'OK JB/IE':
                                        pathOptions.fillColor = '#408000';
                                        break;
                                    case 'KO':
                                        pathOptions.fillColor = '#F00';
                                        break;
                                }
                                return (<Polygon
                                    eventHandlers={{
                                        'click': event => {
                                            popup = createMetadataPopup(layerSelectedPlot._id, layerSelectedPlot.metadata);
                                            popup
                                                .setLatLng(event.latlng)
                                                .openOn(map);
                                        },
                                    }}
                                    key={key}
                                    pathOptions={pathOptions}
                                    positions={layerSelectedPlot.coordinates}
                                />);
                            })}
                        </LayerGroup>
                    </LayersControl.Overlay>}
                    {layerSelectedCoordinates && <LayersControl.Overlay checked name="Selected coordinates">
                        <LayerGroup>
                            <Marker
                                icon={markerIconPin}
                                draggable={true}
                                eventHandlers={{
                                    'mouseover': () => {
                                        const marker = markerRef.current;
                                        if (marker != null) {
                                            marker
                                                .bindPopup(createMarkerPopup(marker, localUserSelection => dispatch(setUserSelection(localUserSelection))))
                                                .openPopup();
                                        }
                                        copyToClipboard(layerSelectedCoordinates.join(','));
                                    },
                                }}
                                position={layerSelectedCoordinates}
                                ref={markerRef}
                            />
                        </LayerGroup>
                    </LayersControl.Overlay>}
                </Pane>
            </LayersControl>
            <div id="panel">
                <Search
                    filtersShow={filtersShow}
                    isLoading={searchIsLoading}
                    profileShow={profileShow}
                    projectShow={projectShow}
                    searchInput={searchInput}
                    setFiltersShow={setFiltersShow}
                    setIsLoading={setSearchIsLoading}
                    setProfileShow={setProfileShow}
                    setProjectShow={setProjectShow}
                    setSearchInput={setSearchInput}
                />
                <GeneralDataLayers
                    filtersShow={filtersShow}
                    searchIsLoading={searchIsLoading}
                />
                <SearchDataLayers
                    filtersShow={filtersShow}
                    onFeatureSelect={onFeatureSelect}
                    onFeaturesSelect={onFeaturesSelect}
                    reloadUserSelection={() => {
                        dispatch(setUserSelection({
                            ...userSelection,
                        }));
                    }}
                    searchIsLoading={searchIsLoading}
                />
                <Profile
                    setShow={setProfileShow}
                    show={profileShow}
                />
            </div>
            <Project
                project={project}
                removePlot={projectRemovePlot}
                setProject={setProject}
                setShow={setProjectShow}
                show={projectShow}
            />
            <Geoman/>
            <PolylineMeasure/>
            <CustomActions/>
            <ScaleControl imperial={true} position="bottomright"/>
            <CountrySelector/>
        </>
    );
}

import {useEffect} from 'react';
import {useMap} from 'react-leaflet/hooks';
import L from 'leaflet';
import 'leaflet.heightgraph';
import 'leaflet.heightgraph/dist/L.Control.Heightgraph.min.css';
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure';
import 'leaflet.polylinemeasure/Leaflet.PolylineMeasure.css';
import {alertService, elevationService} from '../services';

export function PolylineMeasure() {
    const heightgraph = L.control.heightgraph({
        'position': 'bottomleft',
    });
    const map = useMap();

    const showHeightgraph = line => {
        elevationService.get(line.polylinePath._latlngs)
            .then(response => {
                if (response === null) {
                    alertService.error('Problems with obtaining elevation data');
                    return;
                }

                let prevCoordinate = null;
                let distance = 0; // in m, needed for slope calculation
                const coordinates = response.map(data => {
                    let coordinate = [data.location.lat, data.location.lng];
                    if (prevCoordinate !== null) {
                        distance += map.distance(prevCoordinate, coordinate);
                    }
                    prevCoordinate = coordinate;

                    return [data.location.lng, data.location.lat, data.elevation];
                });
                const slopePercentage = ((response[response.length - 1].elevation - response[0].elevation) / distance) * 100;

                const data = [{
                    'type': 'FeatureCollection',
                    'features': [{
                        'type': 'Feature',
                        'geometry': {
                            'type': 'LineString',
                            'coordinates': coordinates,
                        },
                        'properties': {
                            'attributeType': '',
                        },
                    }],
                    'properties': {
                        'Creator': 'Google Elevation API',
                        'records': 1,
                        'summary': `Slope Percentage: ${slopePercentage.toFixed(2)}%`,
                    }
                }];

                heightgraph.addTo(map);
                heightgraph.addData(data);
            });
    };

    useEffect(() => {
        const polylineMeasure = (L.control).polylineMeasure({
            'clearMeasurementsOnStop': false,
            'position': 'topleft',
            'showBearings': false, // show azimut
            'showClearControl': true,
            'unit': 'kilometres',
        });
        polylineMeasure.addTo(map);

        map.on('polylinemeasure:finish', showHeightgraph);

        return () => {
            map.removeControl(polylineMeasure);
        };
    }, []);
}

import {createSlice} from '@reduxjs/toolkit'

export const layersSlice = createSlice({
    'name': 'layers',
    'initialState': {
        'value': {
            'general': null, // null indicates initial state, an empty array means no results
            'ids': {}, // map layer types to layer IDs
            'search': null, // null indicates initial state, an empty array means no results
            'searchAdd': null, // to be added without reset & reload search layers
        },
    },
    'reducers': {
        'reset': state => {
            state.value.general = [];
            state.value.search = null;
        },
        'setGeneral': (state, action) => {
            state.value.general = action.payload;
        },
        'setIds': (state, action) => {
            state.value.ids = action.payload;
        },
        'setSearch': (state, action) => {
            state.value.search = action.payload;
        },
        'setSearchAdd': (state, action) => {
            state.value.searchAdd = action.payload;
        },
    },
});

export const {reset, setGeneral, setIds, setSearch, setSearchAdd} = layersSlice.actions;

export default layersSlice.reducer;

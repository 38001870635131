import {useState} from 'react';
import {Form, InputGroup} from 'react-bootstrap';

export function FilterLineInputLock({defaultIsLocked, defaultValue, name, updateValue, isDisabled}) {
    const [isLocked, setIsLocked] = useState(defaultIsLocked);
    const [value, setValue] = useState(defaultValue);

    const splitPosition = name.indexOf('_');
    const label = name.slice(splitPosition + 1);
    const type = name.slice(0, splitPosition);

    const getLabel = () => {
        if (true === isDisabled || true === isLocked) {
            return label;
        }

        return (
            <>
                {label}{'NUM' === type && null !== value && <><span className="fw-bold ps-1">≥ {value}</span></>}
                <a className="ms-2" onClick={event => {
                    event.stopPropagation();
                    setIsLocked(true);
                    updateValue(null); // reset to null
                }} role="button">clear</a>
            </>
        );
    };

    const renderInput = () => {
        switch (type) {
            case 'NUM':
                return (
                    <InputGroup className="mb-2" size="sm">
                        <Form.Label className={`mb-0` + (true === isLocked ? ` text-body-tertiary` : ``)} size="sm">
                            {getLabel()}
                        </Form.Label>
                        <Form.Range
                            defaultValue={defaultValue}
                            disabled={true === isDisabled || true === isLocked}
                            max={100.0}
                            min={0.0}
                            onChange={event => {
                                const newValue = event.currentTarget.value;
                                setValue(newValue);
                                updateValue(newValue);
                            }}
                            step={0.1}
                        />
                    </InputGroup>
                );
            default: // e.g. 'FLAG' and substations, i.e. their code
                return (
                    <Form.Switch
                        checked={true === value}
                        disabled={true === isDisabled || true === isLocked}
                        label={getLabel()}
                        onChange={event => {
                            const newValue = event.currentTarget.checked;
                            setValue(newValue);
                            updateValue(newValue);
                        }}
                    />
                );
        }
    };

    return (
        <span
            onClick={() => {
                // unlock
                if (true === isLocked) {
                    setIsLocked(false);
                    updateValue(value); // set to value which kept previous state
                }
            }}
        >
            {renderInput()}
        </span>
    );
}

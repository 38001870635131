import {useEffect, useState} from 'react';
import {renderToString} from 'react-dom/server';
import {useMap} from 'react-leaflet/hooks';
import L from 'leaflet';
import 'leaflet-easybutton/src/easy-button';
import {Bullseye as IconBullseye, HouseFill as IconHouse, Trash3Fill as IconTrash} from 'react-bootstrap-icons';

export function CustomActions() {
    const map = useMap();
    const circleFeatureGroup = L.featureGroup().addTo(map);

    const [bufferCircleRadius, setBufferCircleRadius] = useState(50);
    const [isActionBufferCircleActive, setIsActionBufferCircleActive] = useState(false);

    const addButton = (title, icon, onActive, onInactive) => {
        const iconString = renderToString(icon);
        return L.easyButton({
            'states': [{
                'stateName': 'button-inactive',
                'icon': iconString,
                'title': title,
                'onClick': onInactive,
            }, {
                'stateName': 'button-active',
                'icon': iconString,
                'title': title,
                'onClick': onActive,
            }],
        }).addTo(map);
    };

    const createActionBufferCircle = () => {
        const title = 'Circle Buffer';
        const icon = <IconBullseye title={title}/>;
        const button = addButton(title, icon, btn => {
            btn.state('button-inactive');
            setIsActionBufferCircleActive(false);
            const container = document.getElementById('circle-buffer-action');
            container.classList.add('d-none');
        }, btn => {
            btn.state('button-active');
            setIsActionBufferCircleActive(true);
            const container = document.getElementById('circle-buffer-action');
            container.classList.remove('d-none');
        });

        // add additional logic to button
        const buttonContainer = document.getElementsByClassName('easy-button-container')[0];
        const container = document.createElement('div');
        container.id = 'circle-buffer-action';
        container.className = 'leaflet-pm-actions-container d-none';
        const action = document.createElement('div');
        action.className = 'leaflet-pm-action';
        const a = document.createElement('a');
        a.className = 'pe-2';
        a.textContent = `Radius (m)`;
        action.appendChild(a);
        const input = document.createElement('input');
        L.DomEvent.disableClickPropagation(input);
        input.className = 'ps-1';
        input.maxLength = 4;
        input.onkeyup = () => {
            let newRadius = parseInt(input.value, 10);
            if (input.value !== '' && Number.isInteger(newRadius) === false) {
                input.value = bufferCircleRadius;
                newRadius = bufferCircleRadius;
            }

            setBufferCircleRadius(newRadius);
        };
        input.type = 'text';
        input.value = bufferCircleRadius;
        action.appendChild(input);
        container.appendChild(action);
        buttonContainer.appendChild(container);

        return button;
    };

    const createActionBufferObjects = () => {
        const title = 'Add buffers';
        const button = addButton(title, <IconHouse title={title} />, btn => {
            btn.state('button-inactive');
            const container = document.getElementById('objects-buffer-action');
            container.classList.add('d-none');
        }, btn => {
            btn.state('button-active');
            const container = document.getElementById('objects-buffer-action');
            container.classList.remove('d-none');
        });

        // add additional logic to button
        const buttonContainer = document.getElementsByClassName('easy-button-container')[1];
        const container = document.createElement('div');
        container.id = 'objects-buffer-action';
        container.className = 'leaflet-pm-actions-container d-none';
        const action = document.createElement('div');
        action.className = 'leaflet-pm-action';
        [{
            'color': '#FF7518',
            'height': 3,
            'title': 'JBox',
            'width': 10,
        }, {
            'color': '#FF4433',
            'height': 10,
            'title': 'JBox Bail',
            'width': 10,
        }, {
            'color': '#FA8072',
            'height': 25,
            'title': 'IECharge Commune',
            'width': 10,
        }, {
            'color': '#FFAA33',
            'height': 27,
            'title': 'IECharge Département',
            'width': 11,
        }].forEach(element => {
            const a = document.createElement('a');
            a.className = 'd-block';
            a.textContent = element.title;
            a.onclick = () => {
                // apparently this is the only way to set a fixed size polygon: draw circles and pick coordinates from boundaries
                const center = map.getCenter();
                const circleHeight = new L.circle(center, {'radius': element.height/2}).addTo(map);
                const circleHeightBounds = circleHeight.getBounds();
                map.removeLayer(circleHeight);
                const circleWidth = new L.circle(center, {'radius': element.width/2}).addTo(map);
                const circleWidthBounds = circleWidth.getBounds();
                map.removeLayer(circleWidth);
                L.rectangle([[circleHeightBounds.getNorthWest().lat, circleWidthBounds.getNorthWest().lng], [circleHeightBounds.getSouthEast().lat, circleWidthBounds.getSouthEast().lng]], {
                    'color': element.color,
                    'pmIgnore': false,
                    'weight': 2,
                }).addTo(map);
            };
            action.appendChild(a);
        });
        container.appendChild(action);
        buttonContainer.appendChild(container);

        return button;
    };

    const createActionDelete = () => {
        return L.easyButton(renderToString(<IconTrash />), (btn, localMap) => {
            localMap.eachLayer(function(layer){
                if (layer._path !== null && layer.pm !== undefined) {
                    layer.remove()
                }
            });
        }, 'Remove all').addTo(map);
    };

    const drawCircle = (event, radius) => {
        L.circle(event.latlng, {
            'pmIgnore': false,
            'radius': 1,
        }).addTo(circleFeatureGroup); // this is a hack to mark the center of the circle... through another circle :/
        L.circle(event.latlng, {
            'pmIgnore': false,
            'radius': radius,
        }).addTo(circleFeatureGroup);
        circleFeatureGroup.bringToFront();
    };

    useEffect(() => {
        if (isActionBufferCircleActive === true) {
            // @TODO: ensure that this bind is getting triggered first, so that stopPropagation can work
            map.on('click', event => {
                drawCircle(event, bufferCircleRadius);
            });
        }

        return () => {
            map.off('click');
        };
    }, [bufferCircleRadius, isActionBufferCircleActive]);

    useEffect(() => {
        const actionBufferCircle = createActionBufferCircle();
        const actionBufferObjects = createActionBufferObjects();
        const actionDelete = createActionDelete();

        return () => {
            actionBufferCircle.remove();
            actionBufferObjects.remove();
            actionDelete.remove();
        };
    }, []);
}

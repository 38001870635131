import {fetchWithMsal, handleResponse} from "../utils";

const baseUrl = `${process.env.REACT_APP_API_BASE_URL}/layers`;

export const layerService = {
    addElements,
    deleteElement,
    filter,
    getAll,
    getDefaultStyles,
    getHardcoded,
};

function addElements(layerId, data) {
    return fetchWithMsal(`${baseUrl}/${layerId}/elements`, {
        'body': JSON.stringify(data),
        'method': "POST",
    })
        .then(handleResponse)
        .catch((error) => {
            console.error(`layerService.addElements: Layer ID: ${layerId} ; data: ${data}`, error);
            return [];
        });
}

function deleteElement(elementId) {
    return fetchWithMsal(`${baseUrl}/elements/${elementId}`, {
        'method': "DELETE",
    })
        .then(handleResponse)
        .catch((error) => {
            console.error(`layerService.deleteElement: elementId: ${deleteElement}`, error);
            return [];
        });
}

function filter(layerId, column, condition, value) {
    return fetchWithMsal(`${baseUrl}/${layerId}/filter?&filter_col=${column}&filter_cond=${condition}&filter_value=${value}`)
        .then(handleResponse)
        .catch((error) => {
            console.error(`layerService.filter: Layer ID: ${layerId} ; Column: ${column} ; Condition: ${condition} ; Value: ${value}`, error);
            return [];
        });
}

async function getAll() {
    return await fetchWithMsal(`${baseUrl}/?page=1&size=${process.env.REACT_APP_API_MAX_PAGE_SIZE}`)
        .then(handleResponse)
        .then(data => {
            const items = data.items.filter(item => 'properties' in item === false || item.properties.hidden !== true);
            console.info(`${data.items.length - items.length} layers hidden based on their properties.`);
            return items;
        })
        .catch((error) => {
            console.error(`layerService.getAll`, error);
            return null;
        });
}

function getDefaultStyles(name) {
    switch (name) {
        case 'municipalities':
            return {
                'color': "#FF0088",
                'dashArray': '5 10',
                'fillOpacity': 0,
                'weight': 4,
            };
        case 'municipalitiesHighlighted':
            return {
                'color': "#FF50AA",
                'dashArray': '1',
                'fillOpacity': .3,
                'weight': 4,
            };
        case 'plot':
            return {
                'color': "#01796F",
                'fillOpacity': .25,
                'weight': 1,
            };
        case 'plotAll':
            return {
                'color': "#FF0",
                'fillOpacity': .15,
                'opacity': .5,
                'weight': 1,
            };
        case 'plotSelected':
            return {
                'color': "#FF0",
                'fillColor': "#FF0",
                'fillOpacity': .5,
                'opacity': 1,
                'weight': 8,
            };
        case 'projectPlot':
            return {
                'color': '#E0E0E0',
                'fillOpacity': .85,
                'opacity': 1,
                'radius': 10,
                'weight': 2,
            };
        case 'substationZone':
            return {
                'color': "#D5369B", // keep in sync with .leaflet-marker-icon-custom svg in App.css
                'dashArray': "15, 15",
                'fill': false,
                'weigh': 4,
            };
        default:
            return {};
    }
}

function getHardcoded() {
    return {
        'Communes': null,
        'Contraintes RTE - Postes Source': null,
        'French plots': null,
        'French Plots v2': null,
        'Lignes HTA': null,
        'Lignes HTA (extended)': null,
        'Municipalities France': null,
        'Parcelles Brute Force': null,
        'Parcelles Brute Force - F3': null,
        'Parcelles Brute Force - F5': null,
        'Projet plots': null,
        'Projet plots (prod)': null,
        'Projets (prod)': null,
        'Projects Plot': null,
        'Projects': null,
        'PS': null,
        'PS v2': null,
        'Roads': null,
        'Routes': null,
        'Substations (NW Sweden)': null,
        'Substations (NW Italy)': null,
        'Test Roads': null,
        'UF Forced parcels': null,
        'Zonage PLU': null,
        'Zone PS': null,
        'Zone PS v2': null,
    };
}

import {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useMap} from 'react-leaflet/hooks';
import {Accordion, Button, Dropdown, DropdownButton, Form} from 'react-bootstrap';
import {
    ArrowBarUp as IconArrowBarUp,
    Eye as IconEye,
    EyeSlash as IconEyeSlash,
    Filter as IconFilter
} from 'react-bootstrap-icons';
import {FilterLineForm, FilterLineInputLock} from '.';
import {setFilters as setUserFilters} from '../redux/userSlice';
import {operatorService} from '../services';
import {copyDeep, filter, getGeoJsonFromElements, isObjectEmpty} from '../utils';

export function FilterLine({layer, layerRef, resetDataLayer, updateDataLayer}) {
    const dispatch = useDispatch();
    const map = useMap();
    const defaultFormValues = {
        0: layer.defaults,
    };

    const layersIds = useSelector(state => state.layers.value.ids);
    const userFilters = useSelector(state => state.user.value.filters);
    const userSelection = useSelector(state => state.user.value.selection);

    const [filterKey, setFilterKey] = useState(0); // key of a list of form values
    const [formValues, setFormValues] = useState(defaultFormValues);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(true === layer.isFilterOpen);
    const [superlayerFlags, setSuperlayerFlags] = useState([]);
    const [superlayerNums, setSuperlayerNums] = useState([]);
    const [superlayerSubstations, setSuperlayerSubstations] = useState([]);

    // if superlayer, init filter values by looping through all features
    useEffect(() => {
        if (layersIds['plot'] === layer._id) {
            setShow(true);
            const localSuperlayerFlags = new Map();
            const localSuperlayerNums = new Map();
            const localSuperlayerSubstations = new Map();

            // init user filters, only for plot layers (for now)
            const localUserFilters = copyDeep(userFilters);
            layer.data.map(feature => {
                const substations = feature.properties?.substations;
                const userFilter = userFilters[layer._id];
                if (Array.isArray(substations)) {
                    substations.map(substation => {
                        if (false === localSuperlayerSubstations.has(substation.code)) {
                            let defaultValue = null; // null = inactive
                            if (1 === userFilter?.phrase.filter(filter => substation.code === filter.value).length) {
                                defaultValue = true;
                                localUserFilters[layer._id] = addFilter(localUserFilters[layer._id], 'phrase', 'metadata.substations.code', substation.code);
                            }
                            localSuperlayerSubstations.set(substation.code, defaultValue);
                        }
                        Object.keys(substation).forEach(key => {
                            let defaultValue = null; // null = inactive
                            const searchType = getSearchType(key);
                            if ('equals' === searchType && false === localSuperlayerFlags.has(key)) {
                                const filter = userFilter?.equals.filter(filter => filter.key === `metadata.substations.${key}`);
                                if (true === Array.isArray(filter) && 1 === filter.length) {
                                    defaultValue = filter[0].value;
                                    localUserFilters[layer._id] = addFilter(localUserFilters[layer._id], 'equals', key, defaultValue);
                                }
                                localSuperlayerFlags.set(key, defaultValue);
                            }
                            if ('range' === searchType && false === localSuperlayerNums.has(key)) {
                                const filter = userFilter?.range.filter(filter => filter.key === `metadata.substations.${key}`);
                                if (true === Array.isArray(filter) && 1 === filter.length) {
                                    defaultValue = filter[0].value;
                                    localUserFilters[layer._id] = addFilter(localUserFilters[layer._id], 'range', key, defaultValue);
                                }
                                localSuperlayerNums.set(key, defaultValue);
                            }
                        });
                    });
                }
            });

            dispatch(setUserFilters(localUserFilters));
            setSuperlayerFlags(localSuperlayerFlags);
            setSuperlayerNums(localSuperlayerNums);
            setSuperlayerSubstations(localSuperlayerSubstations);
        }

        return () => {
            setSuperlayerFlags([]);
            setSuperlayerNums([]);
            setSuperlayerSubstations([]);
        };
    }, [layer]);

    // disable filter button when we have form values and one of them is invalid
    useEffect(() => {
        let isValid = true;
        Object.keys(formValues).every(key => {
            if (false === isFilterValid(formValues[key])) {
                isValid = false;
                return false;
            }

            return true;
        });

        setIsLoading(defaultFormValues !== formValues && false === isValid);

        return () => {
            setIsLoading(false);
        };
    }, [formValues]);

    // disable filter button when none of the superlayer form values are valid
    useEffect(() => {
        const userFilter = userFilters?.[layer._id];
        let isValid = false;
        if (0 === layer.data.length || // don't disable when no data
            0 < userFilter?.equals.filter(filter => null !== filter.value).length ||
            0 < userFilter?.phrase.filter(filter => null !== filter.value).length ||
            0 < userFilter?.range.filter(filter => null !== filter.value).length) {
            isValid = true;
        }

        setIsLoading(false === isValid);

        return () => {
            setIsLoading(false);
        };
    }, [userFilters]);

    const addFilter = (filters, searchType, key, value) => {
        const filterKey = getSearchTypeKey(searchType, key);
        const filterIndex = filters[searchType].findIndex(filter => filterKey === filter.key);
        const filter = {
            'key': filterKey,
            'value': getSearchTypeValue(searchType, key, value),
        };
        if (-1 !== filterIndex) { // update
            filters[searchType][filterIndex] = filter;
        } else { // add
            filters[searchType].push(filter);
        }

        return filters;
    };

    const formSubmit = () => {
        setIsLoading(true);

        updateDataLayer({
            'layer': {
                ...layer,
                'data': filter(formValues, layer.data),
            },
            'layerRef': layerRef,
        });

        setIsLoading(false);
    };

    const formSubmitSuperlayer = localUserFilter => {
        setIsLoading(true);
        const fields = {
            'equals': [],
            'range': [],
            'phrase': [],
        };

        localUserFilter.equals.map(filter => {
            if (null !== filter.value) {
                fields.equals.push({
                    'key': filter.key,
                    'value': filter.value,
                });
            }
        });

        localUserFilter.range.map(filter => {
            if (null !== filter.value) {
                fields.range.push({
                    'key': filter.key,
                    'value': filter.value,
                });
            }
        });

        localUserFilter.phrase.map(filter => {
            if (null !== filter.value) {
                fields.phrase.push({
                    'key': filter.key,
                    'value': filter.value,
                });
            }
        });

        operatorService.getElements(layersIds['plot'], fields, 'Substation' === userSelection.type ? null : userSelection.geometry)
            .then(elements => {
                updateDataLayer({
                    'layer': {
                        ...layer,
                        'data': getGeoJsonFromElements(elements),
                    },
                    'layerRef': layerRef,
                });
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getDefaultValue = key => {
        switch (getSearchType(key)) {
            case 'range':
                return 0.0;
            default: // equals, phrase
                return true;
        }
    };

    const getSearchTypeKey = (searchType, key) => {
        switch (searchType) {
            case 'equals':
            case 'range':
                return `metadata.substations.${key}`;
            case 'phrase':
                return 'metadata.substations.code';
        }
    }

    const getSearchTypeValue = (searchType, key, value) => {
        if (null === value) {
            return null;
        }

        switch (searchType) {
            case 'equals':
            case 'phrase':
                return value;
            case 'range':
                return parseFloat(value);
        }
    }

    const getSearchType = key => {
        const splitPosition = key.indexOf('_');
        const type = key.slice(0, splitPosition);

        if ('FLAG' === type) {
            return 'equals';
        }
        if ('NUM' === type) {
            return 'range';
        }

        return 'phrase';
    };

    const isFilterValid = values => {
        return values.condition !== '' && values.key !== '' && values.value !== '';
    };

    const renderForm = () => {
        if (false === show) {
            return;
        }

        if (layersIds['plot'] === layer._id) {
            const defaultActiveKeys = [];
            // // Attention: default open active filters will cover the map
            // if (0 < Object.values(userFilters[layer._id].equals).filter(value => null !== value).length) {
            //     defaultActiveKeys.push('Flags');
            // }
            // if (0 < Object.values(userFilters[layer._id].range).filter(value => null !== value).length) {
            //     defaultActiveKeys.push('Nums');
            // }
            // if (0 < Object.values(userFilters[layer._id].phrase).filter(value => null !== value).length) {
            //     defaultActiveKeys.push('Substations');
            // }
            return (
                <Form className="mt-1 superlayer-filters">
                    <Accordion defaultActiveKey={defaultActiveKeys} flush>
                        {renderSuperlayerFormGroup('Flags', 'equals', superlayerFlags, setSuperlayerFlags)}
                        {renderSuperlayerFormGroup('Nums', 'range', superlayerNums, setSuperlayerNums)}
                        {renderSuperlayerFormGroup('Substations', 'phrase', superlayerSubstations, setSuperlayerSubstations, 'Substation' === userSelection.type)}
                    </Accordion>
                    <div className="container g-0 mt-1 text-center">
                        <div className="g-2 row">
                            <div className="col-6">
                                <div className="d-grid">
                                    <Button disabled={true === isLoading} onClick={() => {
                                        // there are two types of resets, one for by-default-filtered plots (on substation level), and the rest (municipality level)
                                        const localUserFilters = {
                                            [layer._id]: layer.defaultPlotFilters,
                                        };
                                        dispatch(setUserFilters(localUserFilters));
                                        // submit with default filters
                                        formSubmitSuperlayer(localUserFilters[layer._id]);
                                        // reset filter values
                                        setSuperlayerFlags([]);
                                        setSuperlayerNums([]);
                                        setSuperlayerSubstations([]);
                                    }} variant="outline-secondary">Reset</Button>
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="d-grid">
                                    <Button disabled={true === isLoading} onClick={() => formSubmitSuperlayer(userFilters[layer._id])} variant="secondary">Filter</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            );
        }

        return (
            <Form className="mt-3" onSubmit={event => event.preventDefault()}>
                <Form.Group>
                    {Object.keys(formValues).map(formValueKey =>
                        <FilterLineForm
                            defaults={layer.defaults}
                            handleUpdate={values => {
                                setFormValues({
                                    ...formValues,
                                    [formValueKey]: values,
                                });
                            }}
                            key={formValueKey}
                            properties={layer.data.length > 0 && 'properties' in layer.data[0] ? layer.data[0].properties : []}
                            submit={formSubmit}
                        />
                    )}
                    <div className="container g-0 text-center">
                        <div className="g-2 row">
                            <div className="col-6">
                                <DropdownButton className="d-grid" title="More" variant="outline-secondary">
                                    <Dropdown.Item  className="d-grid" onClick={() => {
                                        const lastFormValuesKey = Object.keys(formValues).map(localKey => parseInt(localKey)).pop();
                                        setFormValues({
                                            ...formValues,
                                            [lastFormValuesKey+1]: {},
                                        });
                                    }}>Add filter</Dropdown.Item>
                                    {false === isObjectEmpty(formValues) && <Dropdown.Item onClick={() => {
                                        const localFormValues = formValues;
                                        const lastFormValuesKey = Object.keys(formValues).map(localKey => parseInt(localKey)).pop();
                                        delete localFormValues[lastFormValuesKey];
                                        setFormValues({
                                            ...localFormValues,
                                        });
                                    }}>Remove filter</Dropdown.Item>}
                                    <Dropdown.Item onClick={() => {
                                        resetDataLayer();
                                    }}>Reset data</Dropdown.Item>
                                </DropdownButton>
                            </div>
                            <div className="col-6">
                                <div className="d-grid">
                                    <Button disabled={true === isLoading} onClick={formSubmit}>Filter</Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form.Group>
            </Form>
        );
    };

    const renderSuperlayerFormGroup = (title, searchType, elements, setElements, isDisabled = false) => {
        if (0 < elements.size) {
            const activeFilterCount = userFilters?.[layer._id] ? userFilters[layer._id][searchType].filter(filter => null !== filter.value).length : 0;
            return (
                <Accordion.Item eventKey={title}>
                    <Accordion.Header>{title} {0 < activeFilterCount && <span className="badge ms-2 text-bg-light">{activeFilterCount}</span>}</Accordion.Header>
                    <Accordion.Body>
                        {Array.from(elements.keys()).map(key => {
                            const defaultValue = null !== elements.get(key) ? elements.get(key) : getDefaultValue(key);
                            return (
                                <FilterLineInputLock
                                    defaultIsLocked={null === elements.get(key)}
                                    defaultValue={defaultValue}
                                    isDisabled={true === isDisabled}
                                    key={key}
                                    name={key}
                                    updateValue={value => {
                                        const localElements = elements;
                                        localElements.set(key, value);
                                        setElements(localElements);

                                        let localUserFilters = copyDeep(userFilters);
                                        localUserFilters[layer._id] = addFilter(localUserFilters[layer._id], searchType, key, value);
                                        dispatch(setUserFilters(localUserFilters));
                                    }}
                                />
                            );
                        })}
                    </Accordion.Body>
                </Accordion.Item>
            );
        }
    };

    if (layerRef !== null) {
        return (
            <li className="list-group-item" key={`${layer._id}-${filterKey}`}>
                <div className="d-flex justify-content-between align-items-center">
                    <span>
                        {layer.name} <span className="badge bg-secondary ms-2">{layer.data.length}</span>
                    </span>
                    <span>
                        {map.hasLayer(layerRef) === true ?
                            <IconEye onClick={() => {
                                map.removeLayer(layerRef);
                                setFilterKey(filterKey + 1); // force render
                            }} role="button" size={20} title="Hide"/>
                            :
                            <IconEyeSlash onClick={() => {
                                map.addLayer(layerRef);
                                setFilterKey(filterKey + 1); // force render
                            }} role="button" size={20} title="Show"/>}
                        <IconFilter className="me-2 ms-2" onClick={() => {
                            setShow(!show);
                        }} role="button" size={20} title="Toggle filter"/>
                        <IconArrowBarUp onClick={() => {
                            layerRef.bringToFront();
                        }} role="button" size={20} title="Bring to front"/>
                    </span>
                </div>
                {renderForm()}
            </li>
        );
    }
}
